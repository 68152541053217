<template>
  <div class="baseCard">
      证书
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},


  mounted(){},

  methods: {}
}

</script>
<style lang="less" scoped>
</style>